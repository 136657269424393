.Contents{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    background: #eef0f5;
    overflow: hidden;
    transition: .5s;
}

/* div_menu */
.Contents .div_menu{
    width: 200px;
    min-width: 200px;
    height: auto;
    background: #4C4DDC;
    color: #ffffff;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.Contents .div_menu .div_logo{
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #F3F4FF;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Contents .div_menu .div_logo .logo{
    width: 90%;
    max-height: 70px;
    display: flex;
}
.Contents .div_menu .div_opt_menu{
    width: calc(100% - 16px);
    padding: 20px 0px 20px 16px;    
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    cursor: pointer;
}
.Contents .div_menu .div_opt_menu .opt_menu{
    width: calc(100% - 16px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 0px 10px 16px;
    border-radius: 22px 0px 0px 22px;
    color: #ffffff;
}
.Contents .div_menu .div_opt_menu .opt_menu:hover{
    background: #f3f4ff75;
    color: #4c4cdc;
}
.Contents .div_menu .div_opt_menu .opt_menu_active, .Contents .div_menu .div_opt_menu .opt_menu_active:hover{
    background: #F3F4FF;
    color: #4C4DDC;
}
.Contents .div_menu .div_opt_menu .opt_menu .icon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Contents .div_menu .div_opt_menu .opt_menu .name_page{
}
.Contents .div_menu .div_exit{
    width: calc(100% - 40px);
    height: 60px;
    border-top: 1px solid #F3F4FF;
    padding: 0px 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    cursor: pointer;
}
/* end */

/* div_data */
.Contents .div_data{
    width: auto;
    height: 100%;
    flex-grow: 1;
    background: #F3F4FF;
    position: relative;
    overflow-y: auto;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
/* end */

/* div_notification */
.Contents .div_notification{    
    width: 200px;
    min-width: 200px;
    height: auto;
    background: #FFFFFF;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
/* end */