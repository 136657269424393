.Page_Schedule{
    padding-bottom: 40px;
}
.Page_Schedule .div_calendar{
    width: calc(100% - 40px);
    height: auto;
    padding: 20px;
}
.Page_Schedule .div_calendar .rbc-time-content{
    display: none;
}
.Page_Schedule .div_calendar .rbc-calendar{ 
    width: 100%;
}
.Page_Schedule .div_calendar .rbc-toolbar .rbc-toolbar-label{
    text-transform: capitalize;
}
.Page_Schedule .div_calendar .rbc-day-bg+ .Page_Schedule .div_calendar .rbc-day-bg,
.Page_Schedule .div_calendar .rbc-month-row{
    min-height: 88px;
    cursor: pointer;
}