.Comp_Textarea {
    flex-grow: 1;
    position: relative;
    margin-top: 8px;
}
.Comp_Textarea .textarea {
    width: 100%;
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 8px;
    font-family: 'inter';
    outline: none;
    resize: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
    min-height: 52px;
}
.Comp_Textarea .name {
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0px 10px;
    background: #ffffff;
}