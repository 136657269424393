.Comp_Topic{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    color: #303972;
}
.Comp_Topic .name_page{
    font-size: 32px;
    line-height: 28px;
}
.Comp_Topic .div_config{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.Comp_Topic .div_config .div_icon{
    background: #ffffff;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    position: relative;
}
.Comp_Topic .div_config .div_icon .icons{
    border-radius: 40px;
}
.Comp_Topic .div_config .name_user{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    font-family: 'Inter';
}
.Comp_Topic .div_config .name_user .name_{
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
}
.Comp_Topic .div_config .name_user .type_access{
    font-size: 10px;
    line-height: 10px;
}