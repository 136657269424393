.Comp_Btn_AddOrSave{
    background: rgb(76 77 220 / 70%);
    color: #ffffff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    font-family: 'inter';
    padding: 0;
}
.Comp_Btn_AddOrSave .div_btn{
    background: #4C4DDC;
    padding: 11.5px 10px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.Comp_Btn_AddOrSave .title_btn{
    padding: 13.5px 16px;
}