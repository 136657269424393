.Page_Patient_Details .health_insurance{
    width: 320px;
}
.Page_Patient_Details .address_number, .Page_Patient_Details .address_cep{
    width: 80px;
    text-align: center;
}
.Page_Patient_Details .address_uf{
    width: 40px;
    text-align: center;
}
.Page_Patient_Details .patient_cpf{
    width: 120px;
    text-align: center;
}
.Page_Patient_Details .patient_date_birth {
    width: 150px;
}
.Page_Patient_Details .patient_contact{
    width: 170px;
}
.Page_Patient_Details .accommodation{
    width: 160px;
}