.Comp_Btn_Search{
    position: relative;
}
.Comp_Btn_Search input{
    width: 260px;
    border: none;
    border-radius: 20px;
    padding: 14px 10px 14px 40px;
}
.Comp_Btn_Search ::placeholder{
    color: #cac8c8;
}
.Comp_Btn_Search .div_icons{
    position: absolute;
    top: 12px;
    left: 10px;
}