.PopUp .Schedule{
    width: 800px;
    margin-bottom: 40px;
}
.PopUp .Schedule .div_content{
    color: #000000;
    flex-direction: column;
    gap: 16px;
}
.PopUp .Schedule .div_data .content{
    flex-direction: row;
    justify-content: flex-start;
    overflow: inherit;
}
.PopUp .Schedule .contact {
    width: 170px;
}
.PopUp .Schedule .date{
    width: 120px;
}
.PopUp .Schedule .div_Comp_select .Comp_Select{
    width: 100%;
}
.PopUp .Schedule .div_Comp_select .Comp_Select .patient{
    flex-grow: 1;
    text-align: left;
}