.Comp_Btn_ReturnPage{
    color: #4C4DDC;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    cursor: pointer;
}
.Comp_Btn_ReturnPage .icons{
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}